<template>
  <modal :name="modalName" classes="clear_modal">
    <delete-confirm-modal-content :modal-name="modalName" :loading="loading" @confirm="confirmMethod">
      <h5>Are you sure you want to delete these Images?</h5>
      <p>Source Images and Mentions will be removed, Person will not be removed.</p>
    </delete-confirm-modal-content>
  </modal>
</template>
<script>
import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

export default {
  props: {
    loading: Boolean,
    confirmMethod: Function,
  },
  data() {
    return {
      modalName: `images-delete-confirm`,
    };
  },
  components: {DeleteConfirmModalContent},
  name: 'ImagesDeleteConfirmModal',
};
</script>

<style scoped></style>
